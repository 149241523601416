<template>
  <div class="quick-access-section">
    <CollectionSectionHeader
      title="クイックアクセス"
      :count-loading="countLoading"
      :count="count"
      class="quick-access-section__header"
    >
      <template #icon>
        <Icon name="quickaccess" size="md" color="gray-400" />
      </template>
      <template #actions>
        <div class="quick-access-section__header-actions">
          <Icon name="help-circle" size="md" color="gray-400" />
        </div>
      </template>
    </CollectionSectionHeader>
    <QuickAccessItem />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';
import CollectionSectionHeader from '@/components/renewal/collection/collection-section-header.vue';
import QuickAccessItem from '@/components/renewal/collection/quick-access-item.vue';
import { useRepositories } from '@/plugins/repositories';
import Icon from '@/components/renewal/common/icon.vue';

const count = ref<number | null>(null);
const countLoading = ref(false);
const repos = useRepositories();

onBeforeMount(async () => {
  countLoading.value = true;
  try {
    count.value = await repos.quickAccess.getCount();
  } catch (e) {
    console.error(e);
  } finally {
    countLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./quick-access-section.scss" />
