<template>
  <component :is="view" :to="href" class="book-section-header">
    <div class="book-section-header__title">
      <v-skeleton-loader v-if="loading" type="text" boilerplate width="6em" />
      <span v-else>{{ title }}</span>
    </div>
    <div v-if="typeof href === 'string'" class="book-section-header__icon">
      <Icon name="next" size="xs" color="gray-300" />
    </div>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Icon from '@/components/renewal/common/icon.vue';

export interface BookSectionHeaderProps {
  loading?: boolean;
  title: string;
  href?: string;
}

const props = defineProps<BookSectionHeaderProps>();

const view = computed(() => {
  if (typeof props.href === 'string') {
    return 'nuxt-link';
  }
  return 'div';
});
</script>

<style lang="scss" scoped src="./book-section-header.scss" />
