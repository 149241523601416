<template>
  <div class="store-section">
    <StoreSectionHeader :count-loading="countLoading" :count="count" :title="title">
      <template #actions>
        <slot name="actions" />
      </template>
    </StoreSectionHeader>

    <div class="store-section__body">
      <!-- 購入済み書籍の0件表示 -->
      <div v-if="type === 'purchased' && !booksLoading && books.length === 0" class="store-section__no-books">
        <div class="store-section__no-books__empty-thumbnail" />
        <p class="store-section__no-books__desc">
          Legalscape Storeで購入された書籍に、こちらから簡単にアクセスできるようになります
        </p>
      </div>

      <!-- モバイル幅での表示 (画面幅に合わせてv-slide-groupをcreate/destroyすることができないため、要素を分けている) -->
      <div :class="['store-section__content', 'store-section__content--mobile', { loading: booksLoading }]">
        <div class="store-section__content__inner">
          <StoreItem
            v-for="(book, i) in booksLoading ? booksForLoading(4) : books"
            :key="i"
            :type="type"
            :loading="booksLoading"
            :book="book"
            class="store-section__content__item"
          />
        </div>
      </div>

      <!-- モバイル以上の幅で表示 -->
      <div :class="['store-section__content', 'store-section__content--no-mobile', { loading: booksLoading }]">
        <!-- スライダー -->
        <v-slide-group ref="slideGroup" show-arrows class="store-section__slides">
          <template #prev>
            <v-btn icon class="store-section__slides__prev" @click="goToPrevPage">
              <icon name="return" color="gray-300" size="xs" />
            </v-btn>
          </template>

          <template #next>
            <v-btn icon class="store-section__slides__next" @click="goToNextPage">
              <icon name="next" color="gray-300" size="xs" />
            </v-btn>
          </template>

          <v-slide-item
            v-for="(book, i) in booksLoading ? booksForLoading(3) : books"
            :key="i"
            class="store-section__slides__item"
          >
            <StoreItem :type="type" :loading="booksLoading" :book="book" />
          </v-slide-item>
        </v-slide-group>

        <!-- ページネーション -->
        <div v-if="totalPages > 1" class="store-section__pagination">
          <button
            v-for="page in totalPages"
            :key="page"
            :class="['store-section__pagination__btn', { active: currentPage === page }]"
            @click="goToPage(page)"
          >
            <span class="store-section__pagination__btn__inner" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue from 'vue';
import { ref, computed } from 'vue';
import Icon from '@/components/renewal/common/icon.vue';
import StoreSectionHeader from '@/components/renewal/store/store-section-header.vue';
import StoreItem from '@/components/renewal/store/store-item.vue';
import { DocRecord } from 'wklr-backend-sdk/models';

interface SlideGroup {
  scrollOffset: number;
}

const props = defineProps<{
  type: 'recentlyAdded' | 'purchased';
  title: string;
  countLoading?: boolean;
  count?: number | null;
  booksLoading: boolean;
  books: DocRecord[];
}>();

const booksForLoading = (size: number) => Array.from({ length: size }, () => ({} as DocRecord));

const slideGroup = ref<(InstanceType<typeof Vue> & SlideGroup) | null>(null);

// カスタムページネーション
// NOTE: v-slide-groupにはページネーション機能がないため、独自で実装
const slidesPerPage = 3;
const totalPages = computed(() => Math.ceil(props.books.length / slidesPerPage));
const currentPage = ref(1);

const getSlideGroupWrapper = (): HTMLElement | null => {
  const wrapper = slideGroup.value?.$refs.wrapper;
  return wrapper instanceof HTMLElement ? wrapper : null;
};

const getSlideGroupContent = (): HTMLElement | null => {
  const content = slideGroup.value?.$refs.content;

  return content instanceof HTMLElement ? content : null;
};

// スクロール量を計算
// @see: https://github.com/vuetifyjs/vuetify/blob/537d67d81166195adb373e2ed4bdf295a6b6d9a8/packages/vuetify/src/components/VSlideGroup/VSlideGroup.ts#L373
const calculateNewOffset = (diffCurrentAndNewPage: number): number => {
  if (!slideGroup.value) {
    return 0;
  }

  const wrapperWidth = getSlideGroupWrapper()?.clientWidth ?? 0;
  const contentWidth = getSlideGroupContent()?.clientWidth ?? 0;

  const newAbosluteOffset = slideGroup.value.scrollOffset + diffCurrentAndNewPage * wrapperWidth;

  return Math.max(Math.min(newAbosluteOffset, contentWidth - wrapperWidth), 0);
};

const scrollTo = (scrollOffset: number) => {
  if (!slideGroup.value) {
    return;
  }

  slideGroup.value.scrollOffset = scrollOffset;
};

const goToPrevPage = (e: Event) => {
  // v-slider-groupの内部処理(scrollTo)の発火を防ぐために、イベントの伝播を止める
  // NOTE: @click.stopでも同じ挙動だが、まとめて処理した方が分かりやすいため、こちらを使用
  e.stopPropagation();

  if (currentPage.value === 1) {
    return;
  }

  scrollTo(calculateNewOffset(-1));
  currentPage.value--;
};

const goToNextPage = (e: Event) => {
  // v-slider-groupの内部処理(scrollTo)の発火を防ぐために、イベントの伝播を止める
  // NOTE: @click.stopでも同じ挙動だが、まとめて処理した方が分かりやすいため、こちらを使用
  e.stopPropagation();

  if (currentPage.value === totalPages.value) {
    return;
  }

  scrollTo(calculateNewOffset(1));
  currentPage.value++;
};

const goToPage = async (page: number) => {
  if (!slideGroup.value) {
    return;
  }

  scrollTo(calculateNewOffset(page - currentPage.value));
  currentPage.value = page;
};
</script>

<style lang="scss" scoped src="./store-section.scss" />
