<template>
  <div class="binder-section">
    <CollectionSectionHeader title="バインダー" :count-loading="countLoading" :count="count">
      <template #icon>
        <Icon name="binder" size="md" color="gray-400" />
      </template>
      <template #actions>
        <div class="binder-section__header-actions">
          <div>？</div>
        </div>
      </template>
    </CollectionSectionHeader>
    <v-list class="binder-section__setting__list">
      <div class="binder-section__setting__list__container">
        <v-list-item
          v-for="(binder, index) in handleBinderItems"
          :key="index"
          :class="{ 'last-child': handleBinderLastChild(index, handleBinderItems) }"
          class="binder-section__setting__list__item"
        >
          <v-list-item-content class="binder-section__setting__list__item__count">
            <BinderItem :loading="getLoadingState" :binder="binder" />
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <div v-show="showLoadMore" class="binder-section__load-more" :class="{ hidden: showAll }" @click="onClickLoadMore">
      <Icon name="down" size="xs" color="gray-300" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import CollectionSectionHeader from '@/components/renewal/collection/collection-section-header.vue';
import BinderItem from '@/components/renewal/collection/binder-item.vue';
import { Binder } from 'wklr-backend-sdk/models';
import Icon from '@/components/renewal/common/icon.vue';
import { useRepositories } from '@/plugins/repositories';

const BINDER_MAX_COUNT = 3;

const repos = useRepositories();

const loadingBinderItem = ref(true);
const binderItem = ref<Binder[]>([]);
const binderForLoading = computed<Binder[]>(() => Array.from({ length: 3 }, () => ({} as Binder)));

const showAll = ref<boolean>(false);

const count = ref<number | null>(null);
const countLoading = ref<boolean>(false);

const getLoadingState = computed(() => loadingBinderItem.value);

const showLoadMore = computed(() => {
  return !showAll.value && binderItem.value.length > BINDER_MAX_COUNT && !loadingBinderItem.value;
});

const onClickLoadMore = () => {
  showAll.value = true;
};

const handleBinderItems = computed(() => {
  if (getLoadingState.value) {
    return binderForLoading.value;
  }
  return showAll.value ? binderItem.value : binderItem.value.slice(0, 3);
});

const getAllBinders = async () => {
  try {
    binderItem.value = await repos.binderFolders.getAllBinders(false);
  } catch (e) {
    console.error(e);
  } finally {
    loadingBinderItem.value = false;
  }
};

const getBindersCount = async () => {
  try {
    count.value = await repos.binderFolders.getBindersCount();
  } catch (e) {
    console.error(e);
  } finally {
    countLoading.value = false;
  }
};

const handleBinderLastChild = (index: number, binder: Binder[]) => {
  if (binder.length === 3) return false;
  return index === binder.length - 1;
};

onBeforeMount(async () => {
  await getAllBinders();
  await getBindersCount();
});
</script>

<style src="./binder-section.scss" lang="scss" scoped />
