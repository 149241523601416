<template>
  <div class="store-panel">
    <StorePanelHeader />
    <div class="store-panel__body">
      <StoreSection type="recentlyAdded" title="新着書籍" :books-loading="loadingRecents" :books="recents">
        <template #actions>
          <div class="store-panel__section__header-actions">
            <a target="_blank" href="/recently-added-purchasable">
              <icon name="next" color="gray-300" size="sm" />
            </a>
          </div>
        </template>
      </StoreSection>

      <StoreSection
        type="purchased"
        title="購入済み書籍"
        :count-loading="loadingPurchasedCount"
        :count="purchasedDocsCount"
        :books-loading="loadingPurchased"
        :books="purchasedDocs"
      >
        <template #actions>
          <div class="store-panel__section__header-actions">
            <a target="_blank" href="/purchased">
              <icon name="next" color="gray-300" size="sm" />
            </a>
          </div>
        </template>
      </StoreSection>

      <a :href="getStoreTopPageUrl()" target="_blank" class="store-panel__store">
        <icon name="cart-outline" color="gray-0" size="md" />
        <div class="store-panel__store__text">新刊書籍を購入</div>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { DocRecord } from 'wklr-backend-sdk/models';
import Icon from '@/components/renewal/common/icon.vue';
import StorePanelHeader from '@/components/renewal/store/store-panel-header.vue';
import StoreSection from '@/components/renewal/store/store-section.vue';
import { useRepositories } from '@/plugins/repositories';
import { getShopifyMultipassAuthUrl } from '@/utils/shopifyUtils';

const $repositories = useRepositories();

// 新着書籍
const loadingRecents = ref(true);
const recents = ref<DocRecord[]>([]);

const getRecentlyAdded = async () => {
  recents.value = await $repositories.docs.recentlyAddedPurchasable(10);
  loadingRecents.value = false;
};

// 購入済み書籍
const loadingPurchased = ref(true);
const purchasedDocs = ref<DocRecord[]>([]);
const loadingPurchasedCount = ref(true);
const purchasedDocsCount = ref(0);

const getPurchasedDocs = async () => {
  purchasedDocs.value = await $repositories.users.purchasedDocuments(3);
  loadingPurchased.value = false;
};

const getPurchasedDocsCount = async () => {
  try {
    const { count } = await $repositories.users.purchasedDocumentsCount();
    purchasedDocsCount.value = count;
  } catch (e) {
    console.error(e);
  } finally {
    loadingPurchasedCount.value = false;
  }
};

const getStoreTopPageUrl = (): string => {
  const storeOrigin = process.env.SHOPIFY_STORE_ORIGIN;
  if (!storeOrigin) {
    return '';
  }
  return getShopifyMultipassAuthUrl(storeOrigin);
};

onMounted(() => {
  getRecentlyAdded();
  getPurchasedDocs();
  getPurchasedDocsCount();
});
</script>

<style lang="scss" scoped src="./store-panel.scss" />
