<template>
  <div>
    <div v-if="loadingBinder" class="binder-item">
      <v-skeleton-loader type="text" boilerplate class="binder-item__loader" />
    </div>
    <nuxt-link v-else :to="`/binder/${binder.id}`" class="binder-item binder-item__color" :class="`-${colorCode}`">
      <div class="binder-item__header">
        <BinderItemLabel :title="binder.name" :count="getCount(binder.id)">
          <template #actions>
            <div class="binder-item__header__actions">
              <Icon name="next" size="sm" color="gray-300" />
            </div>
          </template>
        </BinderItemLabel>
      </div>
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
import { useRepositories } from '@/plugins/repositories';
import { ref, computed, onBeforeMount } from 'vue';
import type { KeyCount } from 'wklr-backend-sdk/models';
import Icon from '@/components/renewal/common/icon.vue';
import { Binder } from 'wklr-backend-sdk/models';
import { highlightColorName } from '@/utils/binderFoldersUtils';
import BinderItemLabel from '@/components/renewal/collection/binder-item-label.vue';

export interface BinderItemProps {
  binder: Binder;
  loading?: boolean;
}

const props = defineProps<BinderItemProps>();

const colorCode = computed(() => {
  return highlightColorName(props.binder.highlightColor);
});
const counts = ref<KeyCount[] | null>(null);
const repositories = useRepositories();

const getCount = (id: string): number | null => {
  const count = counts.value?.find((v) => v.key === id)?.count;
  return count ?? null;
};

const loadingBinder = computed(() => props.loading);

onBeforeMount(async () => {
  counts.value = await repositories.binderFolders.getItemsCountPerBinder();
});
</script>

<style lang="scss" scoped src="./binder-item.scss" />
