<template>
  <div class="quick-access-item">
    <div class="quick-access-item__body">
      <!-- 0件表示 -->
      <div v-if="!loadingQuickAccessItem && data.length === 0" class="quick-access-item__no-books">
        <div class="quick-access-item__no-books__empty-thumbnail" />
        <p class="quick-access-item__no-books__desc">
          何度も閲覧する文書をクイックアクセスに登録すると、こちらから簡単にアクセスできるようになります
        </p>
      </div>

      <!-- モバイル用 -->
      <div class="quick-access-item__content quick-access-item__content--mobile">
        <div class="quick-access-item__content__inner">
          <div
            v-for="([item], i) in loadingQuickAccessItem ? quickAccessItemForLoading : data"
            :key="i"
            class="quick-access-item__content--mobile__last-child"
          >
            <v-skeleton-loader
              v-if="loadingQuickAccessItem"
              type="image"
              tile
              boilerplate
              class="quick-access-item__slider__thumbnail quick-access-item__slider__thumbnail__img"
            />
            <a v-else :href="item.url" target="_blank" class="quick-access-item__slider__thumbnail">
              <img
                :src="item.thumbnailURI || NO_IMAGE"
                class="quick-access-item__slider__thumbnail__img"
                alt="新着書籍サムネイル"
              />
            </a>
          </div>
        </div>
      </div>

      <!-- モバイル以上の幅で表示 -->
      <div class="quick-access-item__content quick-access-item__content--no-mobile">
        <v-slide-group ref="slideGroup" class="quick-access-item__slides" show-arrows>
          <template #prev>
            <v-btn icon class="quick-access-item__slides__prev" @click="goToPrevPage">
              <icon name="return" color="gray-400" size="xs" />
            </v-btn>
          </template>

          <template #next>
            <v-btn icon class="quick-access-item__slides__next" @click="goToNextPage">
              <icon name="next" color="gray-400" size="xs" />
            </v-btn>
          </template>

          <v-slide-item
            v-for="([item, rawItem], i) in loadingQuickAccessItem ? quickAccessItemForLoading : data"
            :key="i"
            target="_blank"
            :href="item.url"
            class="quick-access-item__slider"
          >
            <div>
              <!-- TODO ローダーの位置修正 -->
              <v-skeleton-loader
                v-if="loadingQuickAccessItem"
                type="image"
                tile
                boilerplate
                class="quick-access-item__slider__thumbnail quick-access-item__slider__thumbnail__img"
              />
              <a v-else :href="item.url" target="_blank" class="quick-access-item__slider__thumbnail">
                <div v-on="wrapHover(item, null)">
                  <v-menu offset-y :nudge-width="-32" :nudge-height="-32" :close-on-content-click="false" z-index="100">
                    <template #activator="{ on: menuOn, attrs: menuAttr }">
                      <v-btn
                        v-show="hoveredItem == item"
                        class="quick-access-item__slider__thumbnail__menu-btn"
                        v-bind="menuAttr"
                        height="25"
                        width="25"
                        fab
                        small
                        slim
                        v-on="{
                          ...menuOn,
                          // @ts-ignore
                          click: (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            menuOn.click(event);
                          },
                        }"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="editQuickAccessItem(rawItem)">
                        <v-icon left size="18">mdi-file-document</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>メモを編集</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="removeQuickAccessItem(rawItem)">
                        <v-icon left size="18">mdi-delete</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>削除</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <img
                    :src="item.thumbnailURI || NO_IMAGE"
                    class="quick-access-item__slider__thumbnail__img"
                    alt="新着書籍サムネイル"
                  />
                </div>
              </a>
            </div>
          </v-slide-item>
        </v-slide-group>
        <!-- ページネーション -->
        <div v-if="totalPages > 1 && !loadingQuickAccessItem" class="quick-access-item__pagination">
          <button
            v-for="page in totalPages"
            :key="page"
            :class="['quick-access-item__pagination__btn', { active: currentPage === page }]"
            @click="goToPage(page)"
          >
            <span class="quick-access-item__pagination__btn__inner" />
          </button>
        </div>
      </div>
      <delete-quick-access-item-dialog ref="deleteQuickAccessItemDialog" :success="updateQuickAccessItems" />
      <modify-quick-access-item-dialog ref="modifyQuickAccessItemDialog" :success="updateQuickAccessItems" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue from 'vue';
import { ref, computed, onMounted } from 'vue';
import DeleteQuickAccessItemDialog from '@/components/quickAccess/delete-item.vue';
import ModifyQuickAccessItemDialog from '@/components/quickAccess/modify-item.vue';
import {
  QuickAccessItemTypeDocumentPropertiesViewTypeEnum,
  QuickAccessItemTypePdfPropertiesViewTypeEnum,
  QuickAccessItemTypeWebPropertiesViewTypeEnum,
  QuickAccessItemWithBibliography,
} from 'wklr-backend-sdk/models';
import { useRepositories } from '@/plugins/repositories';
import NO_IMAGE from '@/assets/noimage.png';
import Icon from '@/components/renewal/common/icon.vue';

export interface QuickAccessItemType {
  id: string;
  description: string;
  url: string;
  thumbnailURI: string | null;
  title: string;
  headingText: string;
  authors: string[];
  publisher: string;
  publishedOn: string;
  updatedAt: string;
}

const $repositories = useRepositories();

// ページネーション
const slidesPerPage = 3;
const slideGroup = ref<(InstanceType<typeof Vue> & { scrollOffset: number }) | null>(null);
const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(data.value.length / slidesPerPage));

// クイックアクセスアイテム
const loadingQuickAccessItem = ref(true);
// TODO any型をちゃんとした型にする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const quickAccessItemForLoading = Array.from({ length: 3 }, () => [{} as any, {} as any]);

const data = ref<[QuickAccessItemType, QuickAccessItemWithBibliography][]>([]);
const hoveredItem = ref<QuickAccessItemType | null>(null);

// ダイアログ
const modifyQuickAccessItemDialog = ref<InstanceType<typeof ModifyQuickAccessItemDialog> | null>(null);
const deleteQuickAccessItemDialog = ref<InstanceType<typeof DeleteQuickAccessItemDialog> | null>(null);

const editQuickAccessItem = (item: QuickAccessItemWithBibliography): void => {
  modifyQuickAccessItemDialog.value?.show({ ...item });
};

const removeQuickAccessItem = (item: QuickAccessItemWithBibliography): void => {
  deleteQuickAccessItemDialog.value?.show({ ...item });
};

const updateQuickAccessItems = async (): Promise<void> => {
  try {
    loadingQuickAccessItem.value = true;
    const items = await $repositories.quickAccess.getItems();
    data.value = items.map((item: QuickAccessItemWithBibliography) => {
      let url;
      switch (item.viewType) {
        case QuickAccessItemTypeWebPropertiesViewTypeEnum.Web:
          url = `/document/${item.doc.id}#key=${item.key}`;
          break;
        case QuickAccessItemTypePdfPropertiesViewTypeEnum.Pdf:
          url = `/document/${item.doc.id}?view=pdf#page=${item.pageSeq + 1}`;
          break;
        case QuickAccessItemTypeDocumentPropertiesViewTypeEnum.Document:
          url = `/document/${item.doc.id}`;
          break;
        default:
          console.error('未知の viewType です', item);
          url = '';
      }
      return [
        {
          id: item.id,
          description: item.description,
          url,
          thumbnailURI: item.doc.thumbnailURI ?? NO_IMAGE,
          title: item.doc.title,
          // FIXME: API で headingText に doc.title を詰めて返すようにする
          headingText: 'headingText' in item ? item.headingText : item.doc.title,
          authors: item.doc.authors,
          publisher: item.doc.publisher,
          publishedOn: new Date(item.doc.publishedOn ?? 0).toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          updatedAt: new Date(item.updatedAt * 1000).toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        },
        item,
      ];
    });
  } catch (e) {
    console.error(e);
  } finally {
    loadingQuickAccessItem.value = false;
  }
};

const getSlideGroupWrapper = (): HTMLElement | null => {
  const wrapper = slideGroup.value?.$refs.wrapper;
  return wrapper instanceof HTMLElement ? wrapper : null;
};

const getSlideGroupContent = (): HTMLElement | null => {
  const content = slideGroup.value?.$refs.content;

  return content instanceof HTMLElement ? content : null;
};

const calculateNewOffset = (diffCurrentAndNewPage: number): number => {
  if (!slideGroup.value) {
    return 0;
  }

  const wrapperWidth = getSlideGroupWrapper()?.clientWidth ?? 0;
  const contentWidth = getSlideGroupContent()?.clientWidth ?? 0;

  const newAbosluteOffset = slideGroup.value.scrollOffset + diffCurrentAndNewPage * wrapperWidth;

  return Math.max(Math.min(newAbosluteOffset, contentWidth - wrapperWidth), 0);
};

const scrollTo = (scrollOffset: number) => {
  if (!slideGroup.value) {
    return;
  }

  slideGroup.value.scrollOffset = scrollOffset;
};

const goToPrevPage = (e: Event) => {
  // v-slider-groupの内部処理(scrollTo)の発火を防ぐために、イベントの伝播を止める
  // NOTE: @click.stopでも同じ挙動だが、まとめて処理した方が分かりやすいため、こちらを使用
  e.stopPropagation();

  if (currentPage.value === 1) {
    return;
  }

  scrollTo(calculateNewOffset(-1));
  currentPage.value--;
};

const goToNextPage = (e: Event) => {
  // v-slider-groupの内部処理(scrollTo)の発火を防ぐために、イベントの伝播を止める
  // NOTE: @click.stopでも同じ挙動だが、まとめて処理した方が分かりやすいため、こちらを使用
  e.stopPropagation();

  if (currentPage.value === totalPages.value) {
    return;
  }

  scrollTo(calculateNewOffset(1));
  currentPage.value++;
};

const goToPage = async (page: number) => {
  if (!slideGroup.value) {
    return;
  }

  scrollTo(calculateNewOffset(page - currentPage.value));
  currentPage.value = page;
};

const wrapHover = (
  item: QuickAccessItemType,
  on: { [eventName: string]: (e: Event) => void } | null,
): { [eventName: string]: (e: Event) => void } => {
  return {
    ...on,
    mouseenter: (e: Event) => {
      hoveredItem.value = item;
      if (on?.mouseenter) {
        return on.mouseenter(e);
      }
    },
    mouseleave: (e: Event) => {
      if (hoveredItem.value === item) {
        hoveredItem.value = null;
      }
      if (on?.mouseleave) {
        return on.mouseleave(e);
      }
    },
  };
};

onMounted(() => {
  updateQuickAccessItems();
});
</script>

<style lang="scss" scoped src="./quick-access-item.scss" />
