import { defineComponent } from 'vue';
import { useTelemetry } from '@/plugins/telemetry';
import IMAGE_1 from '@/assets/2024_bnr1160_1.png';
import IMAGE_2 from '@/assets/2024_bnr1160_3.png';
export default defineComponent({
  setup: function setup() {
    var LP_URL_A = 'https://store.legalscape.jp/?utm_source=legalscape&utm_medium=referral&utm_campaign=top_banner_202403';
    var LP_URL_B = 'https://store.legalscape.jp/p/9784785731328?utm_source=legalscape&utm_medium=referral&utm_campaign=top_banner_202403';
    var telemetry = useTelemetry();
    var route = useRoute();
    var banners = ref([{
      id: 'store-lp-2',
      url: LP_URL_B,
      image: IMAGE_2
    }, {
      id: 'store-lp',
      url: LP_URL_A,
      image: IMAGE_1
    }]);
    var handleClick = function handleClick() {
      telemetry.sendClickTelemetry({
        button: 'top-banner__store-lp'
      }, route);
    };
    return {
      handleClick: handleClick,
      banners: banners
    };
  }
});