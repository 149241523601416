<template>
  <div v-if="show" class="back-to-org-domain">
    <a :href="destination" class="back-to-org-domain__text">組織アカウント（ {{ orgDomain }} ）にアクセスする</a>
    <Icon name="next" size="xs" color="orange-100" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useAuth } from '@/plugins/auth';
import { GetAccountResponse } from 'wklr-backend-sdk/models';
import { useRepositories } from '@/plugins/repositories';
import Icon from '@/components/renewal/common/icon.vue';

const auth = useAuth();
const repositories = useRepositories();

const account = ref<GetAccountResponse | null>(null);
const orgDomain = computed(() => {
  if (!account.value) return undefined;
  const nonPersonalOrganizations = account.value.organizations.filter(
    (organization) => organization.oid !== 'personal',
  );
  return nonPersonalOrganizations.length ? `${nonPersonalOrganizations[0].subdomain}.legalscape.jp` : undefined;
});
const destination = computed(() => (orgDomain.value ? `https://${orgDomain.value}` : undefined));
const isPersonal = computed(() => {
  // auth.user.organization には「現在アクセスしている」組織が入ってくる
  // personal ドメインにアクセスしていて、組織に属している場合に、メッセージを表示する
  return auth.user.organization.oid === 'personal';
});
const show = computed(() => {
  return isPersonal.value && destination.value;
});
onMounted(async () => {
  if (isPersonal.value) {
    account.value = await repositories.accounts.getAccount();
  }
});
</script>
<style lang="scss" scoped src="./back-to-org-domain.scss"></style>
