<template>
  <v-carousel
    ref="carousel"
    v-model="page"
    v-resize="onResize"
    light
    height="250"
    hide-delimiter-background
    class="featured-document-carousel"
    @change="carouselChangeHandler()"
  >
    <v-carousel-item v-for="(docs, idx) in pages()" :key="`${listId}-${idx}`">
      <v-sheet height="100%" tile>
        <v-row class="fill-height" align="center" justify="center">
          <!-- TODO 実験では naturalId.png または naturalId.jpg という文字列からサムネイル、遷移先のURLを直接構築する
                 なお dev, stage には prod にあるドキュメントがないので実際に閲覧しようとすると遷移できない -->
          <viewer-link
            v-for="(doc, docIdx) in docs"
            :key="`${docIdx}-${idx}`"
            :href-document="doc ?? undefined"
            :target="'_blank'"
          >
            <div class="thumbnail-wrapper">
              <img v-if="shouldAppendNewIcon(doc)" src="@/assets/new_icon.svg" alt="NewDocument" class="new-icon" />
              <img
                :src="doc.thumbnailURI || noImage"
                class="thumbnail"
                alt="注目書籍サムネイル"
                @error="handleImageError(doc.id)"
              />
            </div>
          </viewer-link>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>
<script lang="ts">
import { DocRecordTiny } from 'wklr-backend-sdk/models';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { isAccessibleDocRecord, shouldAppendNewIcon } from '@/utils/documentUtils';
import ViewerLink from '@/components/viewer-link.vue';
import NO_IMAGE from '@/assets/noimage.png';

@Component({
  components: {
    ViewerLink,
  },
})
export default class FeaturedDocumentCarousel extends Vue {
  @Prop() documents: DocRecordTiny[] = [];
  @Prop() listId!: string;
  $refs!: {
    carousel: FeaturedDocumentCarousel;
  };

  carouselWidth = 0;
  page = 0;
  noImage = NO_IMAGE;

  mounted() {
    this.onResize();
  }

  onResize() {
    if (this.$refs.carousel) {
      this.carouselWidth = this.$refs.carousel.$el.clientWidth;
    }
  }

  pages(): DocRecordTiny[][] {
    // 左右のボタン幅それぞれ 36px を引いた幅を 1 冊あたりの幅 150px で割る。最低 1 冊は表示する
    const pageSize = Math.max(Math.floor((this.carouselWidth - 36 * 2) / 150), 1);
    const pages: DocRecordTiny[][] = [];
    for (let i = 0; i < this.documents.length; i += pageSize) {
      const docs = this.documents.slice(i, i + pageSize);
      pages.push(docs);
    }
    return pages;
  }

  carouselChangeHandler(): void {
    this.$telemetry.sendClickTelemetry(
      { button: 'featured-document-carousel__change', params: { featuredDocumentList: { listId: this.listId } } },
      this.$route,
    );
  }

  shouldAppendNewIcon(record: DocRecordTiny) {
    if (isAccessibleDocRecord(record)) {
      return shouldAppendNewIcon(record.collectedAt);
    }
    return false;
  }

  // 画像読み込みエラー時のハンドラ
  handleImageError(id: string) {
    this.documents.map((v) => {
      if (v.id === id) {
        return { ...v, thumbnailURI: this.noImage };
      }
      return v;
    });
  }
}
</script>
<style lang="scss" scoped>
.featured-document-carousel {
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  .thumbnail-wrapper {
    position: relative;
    width: 150px;
    height: 160px;
    text-align: center;
    > .thumbnail {
      vertical-align: top;
      max-width: 100%;
      max-height: 100%;
      box-shadow: 0 0 5px #00000020;
    }
  }
  .new-icon {
    position: absolute;
    right: 0;
    padding-right: 15px;
    top: -5px;
  }
  // v-carousel のprev/nextボタン
  &:deep(.v-window__prev),
  &:deep(.v-window__next) {
    background: transparent;
  }
  &:deep(.v-carousel__controls__item.v-btn) {
    color: rgba(0, 0, 0, 0.337);
  }
}
</style>
