<template>
  <div class="store-item">
    <v-skeleton-loader v-if="loading" type="image" tile boilerplate class="store-item__thumbnail" />
    <a v-else :href="getLinkUrl(book.id)" target="_blank" class="store-item__thumbnail">
      <img
        :src="book.thumbnailURI || NO_IMAGE"
        class="store-item__thumbnail__img"
        alt="新着書籍サムネイル"
        @error="handleImageError"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { DocRecord } from 'wklr-backend-sdk/models';

import NO_IMAGE from '@/assets/noimage.png';
import { shopifyDocDetailsUrl } from '@/utils/shopifyUtils';

const props = defineProps<{
  type: 'recentlyAdded' | 'purchased';
  loading?: boolean;
  book: DocRecord;
}>();

const getLinkUrl = (bookId: string) => {
  switch (props.type) {
    case 'recentlyAdded':
      return shopifyDocDetailsUrl(bookId);
    case 'purchased':
      return `/document/${bookId}`;
  }
};

const clonedBook = ref<DocRecord>(structuredClone(props.book));

// 画像読み込みエラー時のハンドラ
const handleImageError = () => {
  clonedBook.value.thumbnailURI = NO_IMAGE;
};
</script>

<style lang="scss" scoped src="./store-item.scss" />
